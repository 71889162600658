<template>

<app-content-box title="Change password" icon="password" :freeze="status === $constants.status.loading">

	<app-input-text :password="true" label="Current" v-model="model.current" placeholder="Enter your current password" />
	<app-input-text :password="true" label="New" v-model="model.new" placeholder="Enter a new password" notes="Your password should be at least 8 characters long, have both upper and lower case, and at least one number." />
	<app-input-text :password="true" label="Confirm new" v-model="model.confirm" placeholder="Confirm your new password" />

	<app-button text="Save" class="account-submit" :disabled="!isValid" v-on:click="onSubmitClick" :status="status" />

</app-content-box>

</template>

<script>

import mixForm from '@/mixin/form'
import { required, sameAs, minLength } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			standalone: true,
			status: false,
			model: {
				current: '',
				new: '',
				confirm: ''
			}
		}

	},
	
	validations: {
		model: {
			current: {
				required
			},
			new: {
				required,
				minLength: minLength(8),
				containsUppercase: function(value) {
					return /[A-Z]/.test(value)
				},
				containsLowercase: function(value) {
					return /[a-z]/.test(value)
				},
				containsNumber: function(value) {
					return /[0-9]/.test(value)
				}
			},
			confirm: {
				required,
				sameAsPassword: sameAs('new')
			}
		}
	},

	methods: {

		onSubmitClick: function() {

			this.status = this.$constants.status.loading

			this.$api.put('session/password', this.model).then(function() {

				this.model.current = ''
				this.model.new = ''
				this.model.confirm = ''

				this.status = this.$constants.status.success

			}.bind(this), function() {

				this.status = this.$constants.status.failed

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.account-submit {
	margin-top: 40px;
}

</style>
